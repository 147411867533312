import { ObjectUtil } from './../utility/object.util';

export class LoginResult {
    public isSuccess: boolean;
    public id: number;
    public jwt: string;
    public refreshToken: string;
    public loginAttemptsLeft: number | null | undefined;

    constructor(data?) {
        ObjectUtil.extend(data, this);
    }
}
