import { GlobalConstants } from 'src/app/core/global-constants';
import { AppRouterService } from 'src/app/core/service/app-router.service';
import { UserContextService } from './user-context.service';
import { UserModel } from './../models/user.model';
import { LoginResult } from '../models/login-result.model';
import { Injectable, inject } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpRestService } from "./http-client/http-rest-client.service";
import { Credentials } from "../models/credentials.model"

@Injectable()
export class LoginService extends BaseService {
    private restService = inject(HttpRestService);
    private userContextService = inject(UserContextService);
    private router = inject(AppRouterService);

    constructor() {
        super();
    }

    public async login(cred: Credentials): Promise<LoginResult> {
        const data = await this.handleResponse(this.restService.post({
            url: `/authorization/login`,
            data: cred
        }));

        return new LoginResult(data);
    }

    public async logout(): Promise<void> {
        await this.restService.post({
            url: `/authorization/logout`,
            noErrorHandling: true
        });
        localStorage.removeItem(GlobalConstants.refreshTokenKey);
        this.userContextService.resetContext();
        this.router.openLogin();
    }

    public async getContext(id: number): Promise<UserModel> {
        const data = await this.handleResponse(this.restService.get({
            url: `/authorization/getUser/` + id,
        }));

        return new UserModel(data);
    }

    public async verifyUser(): Promise<boolean> {
        return !!await this.restService.get({
            url: `/authorization/verifyUser/`,
            noErrorHandling: true
        });
    }

    public async setUserContext(): Promise<void> {
        var context = localStorage.getItem(GlobalConstants.userContextKey);
        if (context) {
            this.setContext(JSON.parse(context));
            return;
        }
        let data;
        try {
            data = await this.restService.get({
                url: `/authorization/verifyUser/`,
                noErrorHandling: true
            });
        } catch (error) {
            data = false;
        }

        if (!data || data == undefined) {
            this.userContextService.resetContext();
            if (!this.router.isExternalService()) {
                this.router.openLogin(window.location.href);
            }
            return;
        }
        this.setContext(await this.getContext(0));
        location.reload();
    }

    private setContext(user: UserModel) {
        this.userContextService.setUser(user);
        this.userContextService.setAuthorizeStatus(true);
    }
}
